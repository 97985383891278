<template>
  <div class="page-top" :class="{'page-top-between': back && (actions || hasEndSlot)}">
    <div v-if="back" class="page-top__left">
        <a @click="$emit('back-click')" class="d-flex" role="button">
          <Arrow direction="left" />
          <div class="page-top__left-text">{{ backText }}</div>
        </a>
      </div>
    <div v-if="pageSwitcher || actions || hasEndSlot" class="page-top__right">
      <PageSwitcher
        v-if="pageSwitcher && pageSwitcherRoutes"
        :routes="pageSwitcherRoutes"
      />
      <div v-if="actions" class="page-top__right-actions">
        <div @click="$emit('refresh-click')" role="button">
          <SpriteIcon icon="refresh" class="lg" title="Обновить" hover />
        </div>
        <div class="ml-10" role="button">
          <a href="https://ranx.ru/support/" target="_blank">
            <SpriteIcon icon="support" class="lg" title="Техническая поддержка" hover />
          </a>
        </div>
        <template v-if="activeSettings.length">
          <div class="ml-10" role="button">
            <router-link
              v-if="activeSettings.length === 1"
              :to="activeSettings[0].url"
            >
              <SpriteIcon icon="settings" class="lg" title="Настройки" hover />
            </router-link>
            <b-dropdown v-else class="p-0" variant="link" toggle-class="text-decoration-none p-0" no-caret right>
              <template #button-content>
                <SpriteIcon icon="settings" class="lg" title="Настройки" hover />
              </template>
              <b-dropdown-item
                v-for="item in activeSettings"
                :key="item.url"
                :to="item.url"
              >
                {{ item.title }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </div>
      <slot class="ml-10" name="end"></slot>
    </div>
  </div>
</template>

<script>
import Arrow from '@/components/ui/Arrow'
import CanMixin from '@/mixins/CanMixin'
import PageSwitcher from '@/components/sale/PageSwitcher'

export default {
  name: 'TopPanel',
  components: {
    Arrow,
    PageSwitcher
  },
  props: {
    back: {
      type: Boolean,
      default: false
    },
    backText: {
      type: String,
      default: ''
    },
    actions: {
      type: Boolean,
      default: false
    },
    pageSwitcher: {
      type: Boolean,
      default: false
    },
    pageSwitcherRoutes: {
      type: Array,
      default: () => []
    },
    settings: {
      type: Array,
      default: () => []
    }
  },
  mixins: [CanMixin],
  computed: {
    activeSettings () {
      return this.settings.filter(el => el.enabled === true)
    },
    hasEndSlot () {
      return !!this.$slots.end
    }
  }
}
</script>

<style>
.page-top {
  margin-bottom: 30px;
}
.page-top__left {
  display: flex;
  align-items: center;
}
.page-top__left-text {
  margin-left: 15px;
  margin-top: 1px;
  color: var(--title-color);
  font-size: 20px;
  font-weight: 600;
}
.page-top__left:hover, .page-top__left:hover * {
  text-decoration: none;
}
.page-top__right,
.page-top__right-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-top__right-actions {
  margin-left: 30px;
}
.page-top__right .page-top__right-actions [class*="icon"] {
  color: var(--title-color);
}
.page-top-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
