<template>
  <b-dropdown
    class="page-switcher"
    right
    v-if="availableRoutes && availableRoutes.length > 1"
  >
    <template #button-content>
      <span class="rx-text">Раздел:</span> <span class="font-weight-bold">{{ currentRouteTitle }}</span>
    </template>
    <b-dropdown-item
      v-for="route in availableRoutes"
      :key="route.id"
      :to="route.link"
      exact
      @click="$bus.$emit('refreshData')"
    >
      {{ route.title }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import CanMixin from '@/mixins/CanMixin.js'

export default {
  name: 'PageSwitcher',
  props: {
    routes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    availableRoutes () {
      return this.routes.filter(el => !el.perm || this.can(el.perm))
    },
    currentRouteTitle () {
      return this.$route.meta.title
    }
  },
  mixins: [CanMixin]
}
</script>

<style>
.page-switcher .dropdown-toggle {
  background: #fff;
  padding: 6px 15px;
  border: none;
}
</style>
